import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import dog from './dog.jpg'
import { useRef } from 'react';
import LandingPage from './pages/LandingPage';
import NewContents from './pages/NewContents'
import RenderPage from './pages/RenderPage'
import PopularContents from './pages/PopularContents'
import RecentContents from './pages/RecentContents'
import MinimalCard from './pages/MinimalCard'
import MinimalPage from "./pages/MinimalPage"
import MyEditor from "./pages/tinyMcetest"
import SeeBlogsPage from './pages/SeeBlogsPage';
import Blogs from './pages/Blogs'
import ViewMinimalBlogs from './pages/ViewMinimalBlogs'
import SeeAllBlogsPage from './pages/SeeAllBlogsPage'
import LearnMore from './pages/LearnMore';
function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}/>
         <Route path="/NewContents" element={<NewContents />}/>
         <Route path="/RenderPage" element={<RenderPage/>}/>
         <Route path="/PopularContents" element={<PopularContents/>}/>
         <Route path="/RecentContents" element={<RecentContents/>}/>
          <Route path="/MinimalCard" element={<MinimalCard/>}/>
          <Route path="/MinimalPage" element={<MinimalPage/>}/>
          <Route path="/SeeBlogsPage" element={<SeeBlogsPage/>}/>
          <Route path="/LearnMore" element={<LearnMore/>}/>
          <Route path="/SeeAllBlogsPage" element={<SeeAllBlogsPage/>}/>
          <Route path="/blogs" element={<Blogs/>}/>
          <Route path="/ViewMinimalBlogs" element={<ViewMinimalBlogs/>}/>
           <Route path="/tinymce" element={<MyEditor/>}/>
</Routes>
 </BrowserRouter>
</>
  );
}
export default App;
