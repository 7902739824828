/**
 heading,
  img,
  date,
  content
  recentContent
 */
/**
  title,
  content,
  img
 */
import React, { useState } from 'react';
import axios from 'axios';
const UserForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    img:'',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   axios.post("https://legal-counsel-5.onrender.com/recentContent",{
    title:formData.title,
    content:formData.content,
    img:formData.img,
   })
    console.log('Form data submitted:', formData);
  };

  return (
    <div>
      <h1 className='flex flex-col'>User Form</h1>
      <form onSubmit={handleSubmit} className='flex flex-col '>
        <label htmlFor="title" className='p-4'>title:</label>
        <input
        className='p-4 border-2 border-black'
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          required
        />

        <label htmlFor="content" className='p-4'>Content:</label>
        <input className='flex flex-col p-4 border-2 border-black'
          type="text"
          id="content"
          name="content"
          value={formData.content}
          onChange={handleChange}
          required
        />
          <label htmlFor="img" className='p-4'>urllocation:</label>
        <input
          className='p-4 border-2 border-black'
          type="text"
          id="img"
          name="img"
          value={formData.img}
          onChange={handleChange}
          required
        />
           {/* <label htmlFor="Password" className='p-4'>Password:</label>
        <input
        className='p-4 border-2 border-black'
          type="text"
          id="Password"
          name="Password"
          value={formData.Password}
          onChange={handleChange}
          required
        /> */}
        <button type="submit" className='my-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-32 my-10 mx-auto'>Submit</button>
      </form>
    </div>
  );
};
 
export default UserForm;