// import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import { useState } from 'react';
// import { stateToHTML } from 'draft-js-export-html';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// // Component to render HTML content
// const HTMLContent = ({ htmlContent }) => {
//   return <div  dangerouslySetInnerHTML={{ __html: htmlContent }}/>;
// };
// export default function App() {
//   const [result, setResult] = useState('');
//   const [displayed, setDisplayed] = useState(EditorState.createEmpty());

//   const onChange = (editorState) => {
//     setDisplayed(editorState);
//   };

//   const convertCommentFromJSONToHTML = (json) => {
//     try {
//       const contentState = convertFromRaw(JSON.parse(json));
//       const htmlContent = stateToHTML(contentState);
//       return htmlContent;
//     } catch (error) {
//       console.error('Error converting JSON to HTML:', error);
//       return ''; // Return empty string in case of error
//     }
//   };

//   const handlePassData = () => {
//     setResult(JSON.stringify(convertToRaw(displayed.getCurrentContent())));
//   };

//   const handleFinalOutput = () => {
//     if (result) {
//       console.log("::" + result);
//     } else {
//       console.error('Result is empty. Please pass data first.');
//     }
//   };

//   return (
//     <div>
//       <Editor
//         editorState={displayed}
//         wrapperClassName="wrapper-class"
//         editorClassName="editor-class"
//         toolbarClassName="toolbar-class"
//         toolbar={{
//            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
//        inline: {
//     inDropdown: false,
//     className: undefined,
//     component: undefined,
//     dropdownClassName: undefined,
//     options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
//     bold: {  className: undefined },
//     italic: { className: undefined },
//     underline: { className: undefined },
//     strikethrough: { className: undefined },
//     monospace: { className: undefined },
//     superscript: { className: undefined },
//     subscript: { className: undefined },
//   },
//   blockType: {
//     inDropdown: true,
//     options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
//     className: undefined,
//     component: undefined,
//     dropdownClassName: undefined,
//   },
//   fontSize: {
//     options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
//     className: undefined,
//     component: undefined,
//     dropdownClassName: undefined,
//   },
//   fontFamily: {
//     options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
//     className: undefined,
//     component: undefined,
//     dropdownClassName: undefined,
//   },
//   list: {
//     inDropdown: false,
//     className: undefined,
//     component: undefined,
//     dropdownClassName: undefined,
//     options: ['unordered', 'ordered', 'indent', 'outdent'],
//     unordered: {  className: undefined },
//     ordered: { className: undefined },
//     indent: { className: undefined },
//     outdent: {  className: undefined },
//   },
//   textAlign: {
//     inDropdown: false,
//     className: undefined,
//     component: undefined,
//     dropdownClassName: undefined,
//     options: ['left', 'center', 'right', 'justify'],
//     left: {  className: undefined },
//     center: {  className: undefined },
//     right: { className: undefined },
//     justify: {  className: undefined },
//   },
//   colorPicker: {
//     className: undefined,
//     component: undefined,
//     popupClassName: undefined,
//     colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
//       'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
//       'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
//       'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
//       'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
//       'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
//   },
//   link: {
//     inDropdown: false,
//     className: undefined,
//     component: undefined,
//     popupClassName: undefined,
//     dropdownClassName: undefined,
//     showOpenOptionOnHover: true,
//     defaultTargetOption: '_self',
//     options: ['link', 'unlink'],
//     link: {  className: undefined },
//     unlink: {  className: undefined },
//     linkCallback: undefined
//   },
//   emoji: {
//     className: undefined,
//     component: undefined,
//     popupClassName: undefined,
//     emojis: [
//       '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
//       '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
//       '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
//       '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
//       '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
//       '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
//       '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
//       '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
//       '✅', '❎', '💯',
//     ],
//   },
//   embedded: {
//     className: undefined,
//     component: undefined,
//     popupClassName: undefined,
//     embedCallback: undefined,
//     defaultSize: {
//       height: 'auto',
//       width: 'auto',
//     },
//   },
//   image: {
//     className: undefined,
//     component: undefined,
//     popupClassName: undefined,
//     urlEnabled: true,
//     uploadEnabled: true,
//     alignmentEnabled: true,
//     uploadCallback: undefined,
//     previewImage: false,
//     inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
//     alt: { present: false, mandatory: false },
//     defaultSize: {
//       height: 'auto',
//       width: 'auto',
//     },
//   },
//   remove: {  className: undefined, component: undefined },
//   history: {
//     inDropdown: false,
//     className: undefined,
//     component: undefined,
//     dropdownClassName: undefined,
//     options: ['undo', 'redo'],
//     undo: { className: undefined },
//     redo: { className: undefined },
//   },
//   }}
//         onEditorStateChange={onChange}
//       />
//       <button className='bg-cyan-400' onClick={handlePassData}>
//         Pass Data
//       </button>
//       <button className='bg-cyan-400' onClick={handleFinalOutput}>
//         Show HTML Output
//       </button>
//       <div id="comment-div">
//         {result ? (
//           <HTMLContent htmlContent={convertCommentFromJSONToHTML(result)} />
//         ) : (
//           <p>No content to display.</p>
//         )}
//       </div>
//     </div>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { EditorState, ContentState } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
// import htmlToDraft from 'html-to-draftjs';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// const MyEditor = () => {
//   const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
//   const [htmlContent, setHtmlContent] = useState('');
//   useEffect(() => {
//     const initialHtmlContent = '<h2>Hello this is meep</h2><p><span style="font-size: 72px;font-family: Times New Roman;">This is another meep</span></p><p></p>';
//     const contentBlock = htmlToDraft(initialHtmlContent);
//     const interval=setTimeout(()=>{
//         if (contentBlock) {
//       const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
//       const newEditorState = EditorState.createWithContent(contentState);
//       setEditorState(newEditorState);
//       setHtmlContent(initialHtmlContent);
//     }
//     },3000);
//     clearTimeout(interval);
//   }, [htmlContent]);

//   const handleEditorChange = (state) => {
//     const newHtmlContent = stateToHTML(state.getCurrentContent());
//     setEditorState(state);
//     setHtmlContent(newHtmlContent);
//   };

//   return (
//     <div>
//       <Editor
//         editorState={editorState}
//         wrapperClassName="demo-wrapper"
//         editorClassName="demo-editor"
//         onEditorStateChange={handleEditorChange}
//       />
//       <div
//         className="html-content"
//         dangerouslySetInnerHTML={{ __html: htmlContent }}
//       />
//     </div>
//   );
// };

// export default MyEditor;


// import React,{useState} from 'react';
// import { Editor } from '@tinymce/tinymce-react';
// const RichTextEditor = ({ initialValue, onChange }) => {
//   return (
//     <Editor
//       initialValue={initialValue}
//       apiKey='an83as0qfuwmjq107onv2pj6lf5z5cc77ahq7qomqp2plpep'
//       init={{
//         height: 500,
//         directionality: 'ltr',
//         menubar: 'file edit view insert format tools table help',
//         plugins: [
//           'advlist autolink lists link image charmap print preview anchor',
//           'searchreplace visualblocks code fullscreen',
//           'insertdatetime media table paste code help wordcount'
//         ],
//         toolbar:
//           'undo redo | formatselect | bold italic backcolor | \
//           alignleft aligncenter alignright alignjustify | \
//           bullist numlist outdent indent | removeformat | help'
//       }}
//       onEditorChange={onChange}
//     />
//   );
// };

// const App=()=>{
//   const [editorContent, setEditorContent] = useState('');

//   const handleEditorChange = (content, editor) => {
//     setEditorContent(content);
//   };

//   return (
//     <div>
//       <RichTextEditor initialValue={editorContent} onChange={handleEditorChange} />
//       <h2>Editor Content:</h2>
//       <div>{editorContent}</div>
//     </div>
//   );
// };

// export default App

import React,{useState,useEffect, useRef} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { set } from 'mongoose';
export default function App() {
  const [text, setText] = useState('');
  const [SelectedTag, setSelectedTag] = useState('');
  const iframeRef = useRef(null);
  console.log(SelectedTag)
  useEffect(() => {
    const iframeDocument = iframeRef.current.contentDocument;

    // Create a <style> element and append CSS styles to it
    const styleElement = iframeDocument.createElement('style');
    styleElement.innerHTML = text;
    iframeDocument.head.appendChild(styleElement);

    // Set the HTML content inside the <iframe>
    iframeDocument.body.innerHTML = text;
  }, [text]);
  return (
    <div>
      <TagSelector currTag={SelectedTag} setCurrTag={setSelectedTag}/>
    <Editor
      apiKey='an83as0qfuwmjq107onv2pj6lf5z5cc77ahq7qomqp2plpep'
      init={{
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
      }}
      initialValue="Welcome to TinyMCE!"
       onEditorChange={(newValue, editor) => {
        setText(editor.getContent({format: 'html'}));
      }}
    />
   <button type="button" className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900" onClick={()=>{
    axios.post(`https://legal-counsel-5.onrender.comhost:3001/${SelectedTag}`,{text})
    console.log(`https://legal-counsel-5.onrender.comhost:3001/${SelectedTag}`);
   }}>Send data</button>
      <iframe
      title="Styled HTML Renderer"
      ref={iframeRef}
      style={{
        "width":"100%",
        "height":"100%"
      }}// Hide the <iframe> from view
    />
     </div>
  );
}
const TagSelector = ({currTag,setCurrTag}) => {

  const handleTagSelection = (tag) => {
    setCurrTag(tag);
  };
  return (
    <div className="flex justify-center">
      <button
        className={`mr-2 px-4 py-2 rounded-lg ${currTag === 'Popular' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
        onClick={() => handleTagSelection('Popular')}
      >
        Popular
      </button>
      <button
        className={`mr-2 px-4 py-2 rounded-lg ${currTag === 'Recent' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
        onClick={() => handleTagSelection('Recent')}
      >
        Recent
      </button>
      <button
        className={`px-4 py-2 rounded-lg ${currTag === 'Minimalism' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
        onClick={() => handleTagSelection('Minimalism')}
      >
        Minimalism
      </button>
    </div>
  );
};
