/**
  Heading: String,
  img:String,
  date:String,
  content: String,
 */
import React, { useState } from 'react';
import axios from 'axios';
const UserForm = () => {
  const [formData, setFormData] = useState({
    date: '',
    content: '',
    img:'',
    Heading:'',
    color:''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   axios.post("https://legal-counsel-5.onrender.com/minimalContent",{
    date:formData.date,
    content:formData.content,
    img:formData.img,
    Heading:formData.Heading,
    color:formData.color
   })
    console.log('Form data submitted:', formData);
  };

  return (
    <div>
      <h1 className='flex flex-col'>User Form</h1>
      <form onSubmit={handleSubmit} className='flex flex-col '>
        <label htmlFor="title" className='p-4'>Date:</label>
        <input
          className='p-4 border-2 border-black'
          type="text"
          id="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          required
        />

        <label htmlFor="content" className='p-4'>Content:</label>
        <input className=' p-4 border-2 border-black'
          type="text"
          id="content"
          name="content"
          value={formData.content}
          onChange={handleChange}
          required
        />
          <label htmlFor="img" className='p-4'>urllocation:</label>
        <input
          className='p-4 border-2 border-black'
          type="text"
          id="img"
          name="img"
          value={formData.img}
          onChange={handleChange}
          required
        />
         <label htmlFor="Heading" className='p-4'>Heading:</label>
        <input
          className='p-4 border-2 border-black'
          type="text"
          id="Heading"
          name="Heading"
          value={formData.Heading}
          onChange={handleChange}
          required
        />
        <label htmlFor="color" className='p-4'>Color:</label>
        <input className=' p-4 border-2 border-black'
          type="text"
          id="color"
          name="color"
          value={formData.color}
          onChange={handleChange}
          required
        />
        <button type="submit" className='my-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-32 my-10 mx-auto'>Submit</button>
      </form>
    </div>
  );
};
 
export default UserForm;
