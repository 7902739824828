import { useLocation } from "react-router-dom";
import { useState,useEffect } from "react";
import axios from "axios";
function RenderPage(){
     const location = useLocation();
     const dataId = location.state;
     const [apiData,setapiData]=useState({});
     useEffect(()=>{
        const fetchData=async()=>{
            let seekData={};
           const response=await axios.get('https://legal-counsel-5.onrender.com/allContents');
           let finalData=response.data;
           let total=finalData.data.length;
           for(let i=0;i<total;i++){
            //   console.log('Comparing:', JSON.stringify(dataId.id), finalData.data[i]._id.toString());
            if(dataId.id==finalData.data[i]._id){
                console.log("first")
                seekData=finalData.data[i];
                break;
            }
           }
            setapiData(seekData)
        }
        fetchData();
     },[]);
return <div>
    {console.log(apiData)}
   <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 text-center md:text-5xl lg:text-6xl dark:text-white">{apiData.name}</h1>
   <div className="flex justify-center">
   <img src={apiData.image} alt="image" className=" h-80 w-96"/>
   </div>
   <br/>
   <p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">{apiData.content}</p>
</div>
}

export default RenderPage;
