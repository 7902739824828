import React from 'react';
import '../App.css';
import { useNavigate } from "react-router-dom";
import { useRef,useEffect,useState } from 'react';

import axios from 'axios';  
import { Footer } from 'flowbite-react';
import CaptLogo from '../images/CaptLogo.png'
import { Card, Badge } from 'react-bootstrap';
import tarangini from '../images/tarangini.jpeg'
import tarangini_img2 from '../images/tarangini_img2.jpeg'
import tarangini_img3 from '../images/tarangini_img3.jpeg'
import legal2 from '../images/legal2.jpeg'
import legal3 from '../images/legal3.jpeg'
import legal4 from '../images/legal4.jpeg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import aboutUsLegalImg from '../images/aboutUsLegalImg.jpeg'
import { BsDiscord, BsDribbble, BsFacebook, BsGithub, BsInstagram, BsLinkedin, BsTwitter } from 'react-icons/bs';
function LandingPage(){
    const [currImg,setcurrImg]=useState(0);
    const [recentButton,setrecentButton]=useState(false);
    const homeRef=useRef(null);
     const AboutRef = useRef(null);
     const ServicesRef=useRef(null);
     const ContactRef=useRef(null);
     const [currData,setcurrData]=useState([]);
     const [MinimalData,setMinimalData]=useState([]);
     const [loading, setLoading] = useState(true);
     const navigate=useNavigate();
    const scrollToAbout = () => {
    AboutRef.current.scrollIntoView({ behavior: 'smooth' });
  }; 
   const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
  }; 
   const scrollToContact = () => {
    ContactRef.current.scrollIntoView({ behavior: 'smooth' });
  }; 
  const scrollToServices = () => {
    ServicesRef.current.scrollIntoView({ behavior: 'smooth' });
  }; 
 useEffect(() => {
  const fetchData = async () => {
    try {
      if (recentButton) {
        const response = await axios.get('https://legal-counsel-5.onrender.com/recentcontent');
        const result = response.data;
        setcurrData(result.data);
      } else {
        const response = await axios.get('https://legal-counsel-5.onrender.com/popularContent');
        const result = response.data;
        setcurrData(result.data);
      }
    } catch (error) {
      // Handle error, for example, log it or show a user-friendly message
      console.error('Error fetching data:', error);
    }
  };

  fetchData(); // Invoke the asynchronous function

}, [recentButton]);
  useEffect(() => {
   const fetchData = async () => {
        try {
            const response = await axios.get('https://legal-counsel-5.onrender.com/popularContent');
            const response2=await axios.get('https://legal-counsel-5.onrender.com/minimalContent');
            const result=response.data;
            const result2=response2.data;
            setMinimalData(result2.data);
            // setMinimalData(result2.length>=20?result2.substring(0,20)+"...":result2);
            setcurrData(result.data)
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
              setLoading(false);
        }
    };
    fetchData();},[]);
    
    let topImgs=[legal2,legal3]
  let arr=["https://www.canva.com/design/DAF9NrtgWEM/PV29RIvBfmR7E0_5HSI75w/view?utm_content=DAF9NrtgWEM&utm_campaign=designshare&utm_medium=link&utm_source=editor"]
  return(
    <>
<div ref={homeRef}>
<div className="mt-16 flex flex-col md:flex-row items-center md:items-start md:justify-between">
  <div className='relative'>
      <img
        alt="Carousel slide"
        className="lg:w-2/3 my-6c"
        src={topImgs[currImg]}
        style={{
          width:"60vw"
        }}
      />
    <button className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md" onClick={() => { setcurrImg((currImg + 1) % topImgs.length) }}>
        <ChevronLeftIcon className="h-8 w-8" />
      </button>
          <button className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
        onClick={() => { currImg > 0 ? setcurrImg((currImg - 1) % topImgs.length) : setcurrImg(topImgs.length - 1) }}
      >
        <ChevronRightIcon className="h-8 w-8" />
      </button>
</div>
  <div className="mr-20  space-y-4">
    <div className="w-full md:w-auto mt-4 md:mt-0 md:ml-4 flex flex-row">
    <div className="h-10 lg:w-36 bg-[#d9534f] text-white px-4 py-2 rounded-l-md border border-[#d43f3a] cursor-pointer" onClick={() => { setrecentButton(false) }}>Popular Posts</div>
    <div className="h-10 lg:w-36 bg-white text-gray-700 px-4 py-2 rounded-r-md border border-gray-300 cursor-pointer" onClick={() => { setrecentButton(true) }}>Recent Posts</div>
</div>
<div className='mr-16'>
  {
    (() => {
      const cardComponents = [];
          const startIdx = currData.length - 2;
      const recentData = currData.slice(startIdx);// Get the two most recent items
      for (let index = 0; index < recentData.length; index++) {
        const item = recentData[index];
        cardComponents.push(<CardComponent key={index} index={index} currData={item} recentButton={recentButton} len={currData.length}/>);
      }
      return cardComponents;
    })()
  }
</div>
  </div>
</div>
</div>
<div className='mx-4 sm:mx-8 md:mx-12 lg:mx-20 flex flex-wrap'>
{(() => {
  const elements = [];
  for (let index = 0; index < MinimalData.length; index++) {
    const data = MinimalData[index];
    elements.push(<MegaComponent key={index} currData={data} index={index} len={MinimalData.length} />);
  }
  return elements;
})()}
</div>
    <div className='bg-black'>
    <NavbarComponent scrollToAbout={scrollToAbout} scrollToContact={scrollToContact} scrollToHome={scrollToHome} scrollToServices={scrollToServices}/>
      <div >
         <div className="flex overflow-x-auto  space-x-4 overflow-y-hidden" >
  </div>
  </div>
  </div>
<div className="grid grid-cols-2 my-6">
  <div className="grid-col-1 bg-blue-800">
    <br></br>
    <br></br>
    <br></br>
     <br></br>
    <text align='center'>
   <p className='text-white' style={{fontFamily:'Garamond'}}>Your</p>
   <p className='text-white relative bottom-2' style={{fontFamily:'Garamond'}}>Crypto Counsel</p>
   </text>
   <div className='my-2'>
     <text align='center'>
   <h1 className='text-xl text-white' style={{fontFamily:'Garamond',fontSize:'2rem'}}>Providing Tailored</h1>
   <h1 className='text-xl text-white relative top-1' style={{fontFamily:'Garamond',fontSize:'2rem'}}>Legal Counsel</h1>
   <h1 className=' text-xl text-white relative top-2'style={{fontFamily:'Garamond',fontSize:'2rem'}}>and Solutions</h1>
   </text>
   </div>
     <div className='my-6 flex justify-center'style={{fontFamily:'forum',fontSize:'1.3rem'}}>
    <text className='text-white' >We specialize in all aspects of CryptoCurrency and Corporate Law</text>
  </div>
  <div className='flex justify-center' style={{fontFamily:'Garamond'}}>
  <button className={`my-4 px-4 py-2 bg-red-500 text-white rounded-md shadow-md hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-blue-500`} onClick={(()=>{
   navigate('/LearnMore')
  })}>
  LEARN MORE
  </button>
  </div>
  </div>
    <div className="grid-col-1">
     <MyimageComponent />
  </div>
</div>
<Service ref={ServicesRef}/>
<AboutUsComponent ref={AboutRef}/>
<Contact ref={ContactRef}/>
<Component/>
    </>
  )
}
function ChevronLeftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 18-6-6 6-6" />
    </svg>
  )
}
const AboutUsComponent= React.forwardRef((props,ref)=> {
  return (
    <div ref={ref}>
    <div className="bg-black text-white py-16 h-screen">
      <h2 className="text-center text-4xl font-bold uppercase mb-10">Team</h2>
      <div className="flex justify-center space-x-10">
        <div className="text-center">
  <img
    alt="Tarangini"
    className="inline-block  mb-4"
    height="200"
    src={tarangini}
    style={{
      width: "200px",
      height: "200px",
      objectFit: "cover",
    }}
  />
  <h3 className="text-xl font-garamond">Tarangini Goel</h3>
  <p className="text-sm">Founder & Head of Your Crypto Counsel</p>
</div>
<div className="text-center">
  <img
    alt="Priya Makhijani"
    className="inline-block mb-4"
    height="200"
    src={tarangini_img2}
    style={{
      width: "200px",
      height: "200px",
      objectFit: "cover",
    }}
  />
  <h3 className="text-xl font-garamond">Shruti Priya Sharma</h3>
  <p className="text-sm">Mergers & Acquisitions Consultant </p>
</div>
<div className="text-center">
  <img
    alt="Jash Dalia"
    className="inline-block mb-4"
    height="200"
    src={tarangini_img3}
    style={{
      width: "200px",
      height: "200px",
      objectFit: "cover",
    }}
  />
  <h3 className="text-xl font-garamond">Bhavya Khatreja</h3>
  <p className="text-sm">Investments and Corporate Consultant</p>
</div>

      </div>
    </div>
    </div>
  )
})
function ChevronRightIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  )
}
const MegaComponent = ({currData,index,len}) => {
  const navigate = useNavigate();
  if(index>5)return null;
  return (
    <div className='p-4 cursor-pointer'  onClick={()=>{
       navigate(`/ViewMinimalBlogs`, {
    state: {
      contentType:`minimalism/${index}/${len}`
    }})
    }}>
    <Card style={{ width: '202px', backgroundColor: currData.color }} className="rounded-lg overflow-hidden">
      <Card.Header>
        <Badge className="text-uppercase" bg="secondary">
         
        </Badge>
      </Card.Header>
      <Card.Body className="p-4">
        <img
          alt="image"
          className="mb-4"
          height="150"
          src={currData.img}
          style={{
            aspectRatio: '202/150',
            objectFit: 'cover',
          }}
          width="202"
        />
        <div className="text-xs text-gray-500">© {currData.date}</div>
        <h3 className="text-lg font-bold my-2">{currData.Heading}</h3>
        <p className="text-sm">{currData.content.length>=20?currData.content.substring(0,20)+"...":currData.content}</p>
      </Card.Body>
    </Card>
    </div>
  );};

const FrontImageComponent=React.forwardRef((props,ref)=>{
  const [imgNum,setimgNum]=useState(0);
  const [imgUrl,setImgUrl]=useState("");
   {setTimeout(() => {
       {setimgNum(imgNum+1)}
    }, 4500)}
  return( 
    <div ref={ref}>
  <div>
    <div>
    <img src={props.images[imgNum%props.images.length]} className='w-screen' style={{"height":"550px"}}></img>
    </div>
    <ProgressBar imgNum={imgNum} className='mb-8'/>
  </div>
  </div>)
  })
function AddSpacingComponent(){
  return <div className="flex flex-row grow h-20">

  </div>
}

const ProgressBar = ({imgNum}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
     setProgress(0); 
    const interval = setInterval(() => {
      // Update the progress every second
      setProgress(prevProgress => (prevProgress < 100 ? prevProgress + 1 : 100));
    },40);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [imgNum]);

  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${progress}%` }}>
        {/* {progress}% */}
      </div>
    </div>
  );
};
function CardComponent({index,currData,recentButton,len}){
  const navigate = useNavigate();
  // console.log(index)
  const [state,setState]=useState(0);
  useEffect(()=>{
  },[state])
  return (
     <div className="relative w-72 h-48 overflow-hidden rounded-md shadow-md mx-4 my-2 cursor-pointer"  onClick={()=>{
      navigate(`/SeeBlogsPage`, {
    state: {
      contentType:recentButton==true?`recent/${index}/${len}`:`popular/${index}/${len}`
    }
  })
      // console.log(first)
     }}>
      <img
        src={currData.img}// Replace with your image URL
        alt="Card Background"
        className="w-full h-full object-cover"
      />
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-white bg-black bg-opacity-20">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-2" >{currData.title}</h2>
          <p className="text-sm">{currData.content}</p>
        </div>
      </div>
    </div>
  )
}
function NavbarComponent({scrollToAbout,scrollToContact,scrollToHome,scrollToServices}){
    const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate=useNavigate();
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return <>
 <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={CaptLogo} className="h-8 w-10" alt="Crypto Council Logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Crypto Counsel</span>
        </a>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <button
            onClick={toggleNav}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-sticky"
            aria-expanded={isNavOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
          </button>
        </div>
        <div
          className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isNavOpen ? 'block' : 'hidden'}`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
      <li>
        <button href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" aria-current="page" onClick={scrollToHome}>Home</button>
      </li>
      <li>
        <button href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" onClick={scrollToAbout}>About</button>
      </li>
      <li>
        <button href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" onClick={scrollToServices}>Services</button>
      </li>
      <li>
        <button href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" onClick={scrollToContact}>Contact</button>
      </li>
       <li>
        <button href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" onClick={()=>{navigate('/blogs')}}>Blogs</button>
      </li>
    </ul>
  </div>
  </div>
</nav>
</>
}
const BannerOfLegalServices=React.forwardRef((props,ref)=>{
  return <>
  <div ref={ref}>
  <div className='flex flex-row '>
  <h1>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-project-a4a47.appspot.com/o/Untitled%20design%20(1).png?alt=media&token=7c04e4e9-4990-4f9d-817b-bedf373775ed" className='h-3/6 w-4/6'></img>
  </h1>
    <br />
      <h2 className='text-purple-700 text-2xl font-bold'>About Us</h2>
      <h2 className='text-purple-700 text-2xl font-bold'>And About You Too</h2>
      <br />
      <h2 className='text-lg'>
        The global potential of emerging tech businesses and the Web3 industry is explosive, and so is your potential to grow.

        New-age businesses require modern solutions. Your business needs the right fuel for take-off, and our team of specialists knows exactly how to take you to the moon.

        At ZeroTo3, we provide bespoke advisory services tailored to your precise requirements. From inception to execution, our special-ops team will assist you with a 360-degree legal solution to rocket your business. So buckle up, HODL, and enjoy the ride!
      </h2>
  </div>
  </div>
  </>
})

function ImageAndTextComponent({ data }) {
  const [ctr, setCtr] = useState(0);
  const [id, setId] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (ctr !== 0) {
      navigate("/RenderPage", { state: { id } });
    }
  }, [ctr, id]);

  return (
    <div className="flex overflow-x-auto p-4 space-x-4 min-w-max">
      {data.length === 0 ? (
        <p>Loading...</p>
      ) : (
        data.map((item) => {
          const { _id, name, content, image } = item;

          return (
            <div key={_id} className="flex mx-4">
              <img src={image} className="h-28 w-32 mx-2 " alt="alt-text" />
              <div>
                <h3 className='text-white'>{name.length>=10?name.substring(0,10)+"...":name}</h3>
                <p className='text-white'>{content.length>=10?content.substring(0,10)+"...":content}</p>
                <button
                  className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                  onClick={() => {
                    setCtr(ctr + 1);
                    setId(_id);
                  }}
                >
                  View full story
                </button>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

function MyimageComponent(){
  return <div>
     <img src={legal4} style={{ width: '100%', height: '30rem'}}></img>
  </div>
}
const TextAboutUs = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      {/* <h2 className='text-purple-700 text-2xl font-bold'>About Us</h2>
      <h2 className='text-purple-700 text-2xl font-bold'>And About You Too</h2> */}
      <br />
      {/* <h2 className='text-lg'>
        Welcome to YourCryptoCounsel, your ultimate destination for all things crypto legal. Founded with a vision to provide a one-stop platform for crypto companies in need of legal assistance, we offer comprehensive services ranging from finance, registration, funding, to everything in between, all under one roof.

At YourCryptoCounsel, we understand the challenges faced by crypto entrepreneurs in navigating the complex legal landscape. That's why we're here to simplify the process and provide tailored solutions to meet your needs, ensuring seamless compliance and growth for your business.

But we don't stop there. We're passionate about demystifying the world of crypto for everyone. Through our insightful articles and crypto insights, we aim to bring you closer to the world of digital currencies, empowering you to make informed decisions and seize opportunities in this rapidly evolving industry.

With YourCryptoCounsel, you get more than just legal consultancy – you get a fusion of legal journalism and expert counsel, all conveniently available in one place. Join us on this exciting journey as we redefine the future of crypto legal services.
      </h2> */}
         <img src={aboutUsLegalImg}></img>
    </div>
  );
});
const ServiceCard = ({ icon, title, details }) => {
  return (
    <>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div className="mb-9 rounded-[20px] p-10 shadow-2 hover:shadow-lg dark:bg-dark-2 md:px-7 xl:px-10 bg-blue-100">
          <h4 className="mb-[14px] text-2xl font-semibold text-dark dark:text-white">
            {title}
          </h4>
          <p className="text-body-color dark:text-dark-6">{details}</p>
        </div>
      </div>
    </>
  );
};
const Service = React.forwardRef((props,ref) => {
  return (
    <div ref={ref}>
    <section className="pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-primary">
                Our Services
              </span>
              <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                What We Offer
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
              </p>
            </div>
          </div>
        </div>

        <div className="mx-4 flex flex-wrap">
          <ServiceCard className="bg-lime-400"
            title="Corporate Commercial"
            details="Mergers And Acquisitions, Private Equity/Venture Capital, Joint Ventures In India, Commercial Contracts, Banking And Finance, Taxation, Corporate Governance & Compliance Advisory, Corporate Transaction Advisory, Media And Entertainment, Telecom & Broadcasting, Real Estate, Regulatory & Compliance, HR, Employment & Labour, NRI Legal Services, FIU-IND Registration."
          />
           <ServiceCard
            title="FDI"
            details="Entry Strategy, Company Registration (Cross Border), Registration and Approval for Sandbox Schemes (Cross Border), Project Launch Strategy, Launching of tokens, Approval of licenses (Cross Border)."
            style={{ backgroundColor: "green", color: "#FFFFFF" }} 
          />
          <ServiceCard className="bg-lime-400"
            title="Corporate Secretarial"
            details="FEMA Law, Risk Advisory & Due Diligence, Closure Of Companies, Conversion of Companies, Oppression & Mismanagement, Merger & Amalgamation, Demerger"
          />
          <ServiceCard
            title="Dispute Resolution"
            details="Arbitration, Insolvency Bankruptcy & Restructuring, Prevention Of Money Laundering, Enforcement Directorate."
             style={{ backgroundColor: "green", color: "#FFFFFF" }} 
          />
          <ServiceCard
            title="Intellectual Property"
            details="Trademarks, Copyrights, Patents, Designs, Domain Names, Geographical Indications, IP Litigation Services, Global IP Solutions."
             style={{ backgroundColor: "green", color: "#FFFFFF" }} 
          />
  <ServiceCard
            title="Data Protection"
            details="Data Breach Management Services, Data Processing Agreements, Data Localization."
             style={{ backgroundColor: "green", color: "#FFFFFF" }} 
          />
        </div>
      </div>
    </section>
    </div>
  );
});
const Contact = React.forwardRef((props,ref) => {
   const formRef = useRef(null);
     const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    details: '',
  });
 const handleSubmit = (e) => {
    e.preventDefault();
   const inputs = e.target.elements;
let data = {};
for (let i = 0; i < inputs.length - 1; i++) {
  data[inputs[i].name] = inputs[i].value;
}

    // Use axios to send the form data to the server
    axios.post('https://legal-counsel-5.onrender.com/userDetails', data)
      .then(response => {
        // Handle the successful response, if needed
        // console.log(response.data);
      })
      .catch(error => {
        // Handle the error, if needed
        console.error('Error submitting form:', error);
      });
      formRef.current.reset();
  };

       const handleChange = (e) => {
        let name1,email1,phone1,details1="";
            name1=e.name;
            email1=e.email;
            phone1=e.phone;
            details1=e.details;
            let data={};
            data.push(name1);
            data.push(email1);
            data.push(phone1);
            data.push(details1);
            setFormData(data);

  };
  return (
    <>
    <div ref={ref}>
      <section className="relative z-10 overflow-hidden bg-white py-20 dark:bg-dark lg:py-[120px]">
        <div className="container">
          <div className="-mx-4 flex flex-wrap lg:justify-between">
            <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
              <div className="mb-12 max-w-[570px] lg:mb-0">
                <span className="mb-4 block text-base font-semibold text-primary">
                  Contact Us
                </span>
                <h2 className="mb-6 text-[32px] font-bold uppercase text-dark dark:text-white sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                  GET IN TOUCH WITH US
                </h2>
                <p className="mb-9 text-base leading-relaxed text-body-color dark:text-dark-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eius tempor incididunt ut labore e dolore magna aliqua. Ut
                  enim adiqua minim veniam quis nostrud exercitation ullamco
                </p>
                <div className="mb-8 flex w-full max-w-[370px]">
                  <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.6 11.8002L17.7 3.5002C16.65 2.8502 15.3 2.8502 14.3 3.5002L1.39998 11.8002C0.899983 12.1502 0.749983 12.8502 1.04998 13.3502C1.39998 13.8502 2.09998 14.0002 2.59998 13.7002L3.44998 13.1502V25.8002C3.44998 27.5502 4.84998 28.9502 6.59998 28.9502H25.4C27.15 28.9502 28.55 27.5502 28.55 25.8002V13.1502L29.4 13.7002C29.6 13.8002 29.8 13.9002 30 13.9002C30.35 13.9002 30.75 13.7002 30.95 13.4002C31.3 12.8502 31.15 12.1502 30.6 11.8002ZM13.35 26.7502V18.5002C13.35 18.0002 13.75 17.6002 14.25 17.6002H17.75C18.25 17.6002 18.65 18.0002 18.65 18.5002V26.7502H13.35ZM26.3 25.8002C26.3 26.3002 25.9 26.7002 25.4 26.7002H20.9V18.5002C20.9 16.8002 19.5 15.4002 17.8 15.4002H14.3C12.6 15.4002 11.2 16.8002 11.2 18.5002V26.7502H6.69998C6.19998 26.7502 5.79998 26.3502 5.79998 25.8502V11.7002L15.5 5.4002C15.8 5.2002 16.2 5.2002 16.5 5.4002L26.3 11.7002V25.8002Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="w-full">
                    <h4 className="mb-1 text-xl font-bold text-dark dark:text-white">
                      Our Location
                    </h4>
                    <p className="text-base text-body-color dark:text-dark-6">
          Shalimar Bagh, New Delhi, India, 110088
                    </p>
                  </div>
                </div>

                <div className="mb-8 flex w-full max-w-[370px]">
                  <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_941_17577)">
                        <path
                          d="M24.3 31.1499C22.95 31.1499 21.4 30.7999 19.7 30.1499C16.3 28.7999 12.55 26.1999 9.19997 22.8499C5.84997 19.4999 3.24997 15.7499 1.89997 12.2999C0.39997 8.59994 0.54997 5.54994 2.29997 3.84994C2.34997 3.79994 2.44997 3.74994 2.49997 3.69994L6.69997 1.19994C7.74997 0.599942 9.09997 0.899942 9.79997 1.89994L12.75 6.29994C13.45 7.34994 13.15 8.74994 12.15 9.44994L10.35 10.6999C11.65 12.7999 15.35 17.9499 21.25 21.6499L22.35 20.0499C23.2 18.8499 24.55 18.4999 25.65 19.2499L30.05 22.1999C31.05 22.8999 31.35 24.2499 30.75 25.2999L28.25 29.4999C28.2 29.5999 28.15 29.6499 28.1 29.6999C27.2 30.6499 25.9 31.1499 24.3 31.1499ZM3.79997 5.54994C2.84997 6.59994 2.89997 8.74994 3.99997 11.4999C5.24997 14.6499 7.64997 18.0999 10.8 21.2499C13.9 24.3499 17.4 26.7499 20.5 27.9999C23.2 29.0999 25.35 29.1499 26.45 28.1999L28.85 24.0999C28.85 24.0499 28.85 24.0499 28.85 23.9999L24.45 21.0499C24.45 21.0499 24.35 21.0999 24.25 21.2499L23.15 22.8499C22.45 23.8499 21.1 24.1499 20.1 23.4999C13.8 19.5999 9.89997 14.1499 8.49997 11.9499C7.84997 10.8999 8.09997 9.54994 9.09997 8.84994L10.9 7.59994V7.54994L7.94997 3.14994C7.94997 3.09994 7.89997 3.09994 7.84997 3.14994L3.79997 5.54994Z"
                          fill="currentColor"
                        />
                        <path
                          d="M29.3 14.25C28.7 14.25 28.25 13.8 28.2 13.2C27.8 8.15003 23.65 4.10003 18.55 3.75003C17.95 3.70003 17.45 3.20003 17.5 2.55003C17.55 1.95003 18.05 1.45003 18.7 1.50003C24.9 1.90003 29.95 6.80003 30.45 13C30.5 13.6 30.05 14.15 29.4 14.2C29.4 14.25 29.35 14.25 29.3 14.25Z"
                          fill="currentColor"
                        />
                        <path
                          d="M24.35 14.7002C23.8 14.7002 23.3 14.3002 23.25 13.7002C22.95 11.0002 20.85 8.90018 18.15 8.55018C17.55 8.50018 17.1 7.90018 17.15 7.30018C17.2 6.70018 17.8 6.25018 18.4 6.30018C22.15 6.75018 25.05 9.65018 25.5 13.4002C25.55 14.0002 25.15 14.5502 24.5 14.6502C24.4 14.7002 24.35 14.7002 24.35 14.7002Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_941_17577">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="w-full">
                    <h4 className="mb-1 text-xl font-bold text-dark dark:text-white">
                      Phone Number
                    </h4>
                    <p className="text-base text-body-color dark:text-dark-6">
                      (+91)70119 35472
                    </p>
                  </div>
                </div>

                <div className="mb-8 flex w-full max-w-[370px]">
                  <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28 4.7998H3.99998C2.29998 4.7998 0.849976 6.1998 0.849976 7.9498V24.1498C0.849976 25.8498 2.24998 27.2998 3.99998 27.2998H28C29.7 27.2998 31.15 25.8998 31.15 24.1498V7.8998C31.15 6.1998 29.7 4.7998 28 4.7998ZM28 7.0498C28.05 7.0498 28.1 7.0498 28.15 7.0498L16 14.8498L3.84998 7.0498C3.89998 7.0498 3.94998 7.0498 3.99998 7.0498H28ZM28 24.9498H3.99998C3.49998 24.9498 3.09998 24.5498 3.09998 24.0498V9.2498L14.8 16.7498C15.15 16.9998 15.55 17.0998 15.95 17.0998C16.35 17.0998 16.75 16.9998 17.1 16.7498L28.8 9.2498V24.0998C28.9 24.5998 28.5 24.9498 28 24.9498Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="w-full">
                    <h4 className="mb-1 text-xl font-bold text-dark dark:text-white">
                      Email Address
                    </h4>
                    <p className="text-base text-body-color dark:text-dark-6">
                      YourCryptoCounsel@gmail.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="relative rounded-lg bg-white p-8 shadow-lg dark:bg-dark-2 sm:p-12">
                <form ref={formRef} onSubmit={handleSubmit}>
                  <ContactInputBox
                    type="text"
                    name="name"
                    placeholder="Your Name"
                  />
                  <ContactInputBox
                    type="text"
                    name="email"
                    placeholder="Your Email"
                  />
                  <ContactInputBox
                    type="text"
                    name="phone"
                    placeholder="Your Phone"
                  />
                  <ContactTextArea
                    row="6"
                    placeholder="Your Message"
                    name="details"
                    defaultValue=""
                  />
                  <div>
                    <button
                      type="submit"
                      className="w-full rounded border border-primary bg-purple-500 p-3 text-white transition hover:bg-opacity-90"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
                <div>
                  <span className="absolute -right-9 -top-10 z-[-1]">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                        fill="#3056D3"
                      />
                    </svg>
                  </span>
                  <span className="absolute -right-10 top-[90px] z-[-1]">
                    <svg
                      width={34}
                      height={134}
                      viewBox="0 0 34 134"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="31.9993"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 31.9993 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 31.9993 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 31.9993 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 31.9993 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 31.9993 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 31.9993 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 31.9993 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 31.9993 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 31.9993 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 31.9993 1.66665)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 17.3333 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 17.3333 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 17.3333 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 17.3333 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 17.3333 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 17.3333 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 17.3333 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 17.3333 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 17.3333 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 17.3333 1.66665)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 2.66536 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 2.66536 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 2.66536 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 2.66536 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 2.66536 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 2.66536 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 2.66536 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 2.66536 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 2.66536 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 2.66536 1.66665)"
                        fill="#13C296"
                      />
                    </svg>
                  </span>
                  <span className="absolute -bottom-7 -left-7 z-[-1]">
                    <svg
                      width={107}
                      height={134}
                      viewBox="0 0 107 134"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="104.999"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 104.999 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="104.999"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 104.999 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="104.999"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 104.999 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="104.999"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 104.999 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="104.999"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 104.999 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="104.999"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 104.999 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="104.999"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 104.999 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="104.999"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 104.999 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="104.999"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 104.999 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="104.999"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 104.999 1.66665)"
                        fill="#13C296"
                      />
                      <circle
                        cx="90.3333"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 90.3333 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="90.3333"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 90.3333 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="90.3333"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 90.3333 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="90.3333"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 90.3333 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="90.3333"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 90.3333 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="90.3333"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 90.3333 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="90.3333"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 90.3333 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="90.3333"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 90.3333 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="90.3333"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 90.3333 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="90.3333"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 90.3333 1.66665)"
                        fill="#13C296"
                      />
                      <circle
                        cx="75.6654"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 75.6654 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 31.9993 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="75.6654"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 75.6654 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 31.9993 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="75.6654"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 75.6654 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 31.9993 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="75.6654"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 75.6654 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 31.9993 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="75.6654"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 75.6654 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 31.9993 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="75.6654"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 75.6654 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 31.9993 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="75.6654"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 75.6654 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 31.9993 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="75.6654"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 75.6654 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 31.9993 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="75.6654"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 75.6654 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 31.9993 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="75.6654"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 75.6654 1.66665)"
                        fill="#13C296"
                      />
                      <circle
                        cx="31.9993"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 31.9993 1.66665)"
                        fill="#13C296"
                      />
                      <circle
                        cx="60.9993"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 60.9993 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 17.3333 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="60.9993"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 60.9993 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 17.3333 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="60.9993"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 60.9993 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 17.3333 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="60.9993"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 60.9993 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 17.3333 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="60.9993"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 60.9993 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 17.3333 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="60.9993"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 60.9993 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 17.3333 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="60.9993"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 60.9993 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 17.3333 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="60.9993"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 60.9993 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 17.3333 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="60.9993"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 60.9993 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 17.3333 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="60.9993"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 60.9993 1.66665)"
                        fill="#13C296"
                      />
                      <circle
                        cx="17.3333"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 17.3333 1.66665)"
                        fill="#13C296"
                      />
                      <circle
                        cx="46.3333"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 46.3333 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 2.66536 132)"
                        fill="#13C296"
                      />
                      <circle
                        cx="46.3333"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 46.3333 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 2.66536 117.333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="46.3333"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 46.3333 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 2.66536 102.667)"
                        fill="#13C296"
                      />
                      <circle
                        cx="46.3333"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 46.3333 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 2.66536 88)"
                        fill="#13C296"
                      />
                      <circle
                        cx="46.3333"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 46.3333 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 2.66536 73.3333)"
                        fill="#13C296"
                      />
                      <circle
                        cx="46.3333"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 46.3333 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 2.66536 45)"
                        fill="#13C296"
                      />
                      <circle
                        cx="46.3333"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 46.3333 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 2.66536 16)"
                        fill="#13C296"
                      />
                      <circle
                        cx="46.3333"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 46.3333 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 2.66536 59)"
                        fill="#13C296"
                      />
                      <circle
                        cx="46.3333"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 46.3333 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 2.66536 30.6666)"
                        fill="#13C296"
                      />
                      <circle
                        cx="46.3333"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 46.3333 1.66665)"
                        fill="#13C296"
                      />
                      <circle
                        cx="2.66536"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 2.66536 1.66665)"
                        fill="#13C296"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
});

const ContactTextArea = ({ row, placeholder, name, defaultValue }) => {
  return (
    <>
      <div className="mb-6">
        <textarea
          rows={row}
          placeholder={placeholder}
          name={name}
          className="w-full resize-none rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
          defaultValue={defaultValue}
        />
      </div>
    </>
  );
};

const ContactInputBox = ({ type, placeholder, name }) => {
  return (
    <>
      <div className="mb-6">
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
        />
      </div>
    </>
  );
};
function Component() {
  return (
    <Footer container>
      <div className="w-full">
        <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          <div>
            <Footer.Brand
              href="https://google.com"
              src={CaptLogo}
              alt="CaptLogo "
              name="CaptLogo"
            />
          </div>
          <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
          
            <div>
              <Footer.Title title="Follow us" />
              <Footer.LinkGroup col >
                  <Footer.Icon href="#" icon={BsDiscord} />
                 <Footer.Icon href="#" icon={BsInstagram} />
            <Footer.Icon href="https://twitter.com/urCryptoCounsel/" icon={BsTwitter} />
            <Footer.Icon href="#" icon={BsLinkedin} />
            <Footer.Icon href="#" icon={BsDribbble} />
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Legal" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">Privacy Policy</Footer.Link>
                <Footer.Link href="#">Terms &amp; Conditions</Footer.Link>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright href="#" by="YourCyptoCounsel™" year={2024} />
        </div>
      </div>
    </Footer>
  );
}
export default LandingPage;
