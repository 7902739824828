import {useState,useEffect,useRef} from 'react'
import { useLocation } from 'react-router-dom';
export default function SeeAllBlogsPage({htmlData}){
    const [Text,setText]=useState(0);
     const iframeRef = useRef(null);
    //  console.log(htmlData)
    //  console.log(first)
    const location=useLocation();
    useEffect(()=>{
     const { htmlData }=location.state;
     console.log(htmlData)
     setText(htmlData)
     const iframeDocument = iframeRef.current.contentDocument;

    const styleElement = iframeDocument.createElement('style');
    styleElement.innerHTML = Text;
    iframeDocument.head.appendChild(styleElement);

    iframeDocument.body.innerHTML = Text;
    },[])
    useEffect(() => {
        //  console.log(Text)
    const iframeDocument = iframeRef.current.contentDocument;
    
    const styleElement = iframeDocument.createElement('style');
    styleElement.innerHTML = Text;
    iframeDocument.head.appendChild(styleElement);

    iframeDocument.body.innerHTML = Text;
  }, [Text]);
    return (
        <div>
        <iframe
      title="Styled HTML Renderer"
      ref={iframeRef}
      style={{
        "width":"100vw",
        "height":"100vh"
      }}// Hide the <iframe> from view
    />
        </div>
    )
}