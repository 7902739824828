import * as React from 'react';
import axios from 'axios';
import {useEffect,useState,useRef} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea,Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
export default function Main() {
    const [popData,setpopData]=useState(0);
     const [recData,setrecData]=useState(0);
      const [minimalData,setminimalData]=useState(0);
      const [len,setlen]=useState(0);
    useEffect(()=>{
        async function fetchData(){
          const resprec= await axios.get('https://legal-counsel-5.onrender.com/recentcontent');
           const result1 = resprec.data;
             const resppop=await axios.get('https://legal-counsel-5.onrender.com/popularContent');
             const result2=resppop.data;
             const respminimal=await axios.get('https://legal-counsel-5.onrender.com/minimalContent');
             const result3=respminimal.data;
             setrecData(result1.data)
            setpopData(result2.data)
            setminimalData(result3.data)
             setlen(result1.data.length+result2.data.length+result3.data.length)
        }
        fetchData()
    },[])
    return (
        <>
        <NavbarComponent/>
        <Grid container spacing={2}>
            {[...Array(recData.length)].map((_, index) => (
                <Grid key={index} item xs={4}>
                    <ActionAreaCard index={index} currData={recData} type={"recData"}/>
                </Grid>
            ))}
             {[...Array(popData.length)].map((_, index) => (
                <Grid key={index} item xs={4}>
                    <ActionAreaCard index={index} currData={popData} type={"popData"}/>
                </Grid>
            ))}
             {[...Array(minimalData.length)].map((_, index) => (
                <Grid key={index} item xs={4}>
                    <ActionAreaCard index={index} currData={minimalData} type={"minimalData"}/>
                </Grid>
            ))}
        </Grid>
        </>
    );
}
function NavbarComponent(){
    const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate=useNavigate();
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return <>
 <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a  className="flex items-center space-x-3 rtl:space-x-reverse">
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white" onClick={()=>{navigate('/')}}>Crypto Council</span>
        </a>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <button
            onClick={toggleNav}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-sticky"
            aria-expanded={isNavOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
          </button>
        </div>
        <div
          className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isNavOpen ? 'block' : 'hidden'}`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
      <li>
        <button href="#" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500" aria-current="page" onClick={()=>{navigate('/')}}>Home</button>
      </li>
    </ul>
  </div>
  </div>
</nav>
</>
}
function ActionAreaCard({currData,index,type}) {
    // console.log(currData[index])
    const navigate = useNavigate();
    // useEffect(()=>{
    //     axios.get('http://legal-counsel-5.onrender.com/SeeBlogsPage')
    // },[])
    async function handleClick(){
        if(type=='recData'){
                    const response=await axios.get('https://legal-counsel-5.onrender.com/recent')
                    let htmlData=response.data.data[index].data
                    console.log(htmlData)
                      navigate('/SeeAllBlogsPage',{state:{htmlData}}); 
                    //   console.log(htmlData)
            }else if(type=='popData'){
                    const response=await axios.get('https://legal-counsel-5.onrender.com/popular')
                    let htmlData=response.data.data[index].data
                     navigate('/SeeAllBlogsPage', { state: { htmlData } }); 
            }else if(type=='minimalData'){
                    const response=await axios.get('https://legal-counsel-5.onrender.com/minimalism')
                let htmlData=response.data.data[index].data
                 navigate('/SeeAllBlogsPage', { state: { htmlData } }); 
                }
    }
    return (
        <Card sx={{ maxWidth: 345 }} onClick={handleClick}>
            <CardActionArea>
                 {currData[index]==undefined?null:
                <CardMedia
                    component="img"
                    height="140"
                    image={currData[index].img}
                    alt="green iguana"
                />}
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                   {currData[index]==undefined?null:currData[index].heading==undefined?null:currData[index].content.substring(0,10)+"..."}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {currData[index]==undefined?null:currData[index].content}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}