import React, { useState } from 'react';
import axios from 'axios';
const UserForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    content: '',
    image:'',
    Password:''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   axios.post("https://my-web-service3.onrender.com/data",{
    name:formData.name,
    content:formData.content,
    Password:formData.Password,
    image:formData.location,
   })
    console.log('Form data submitted:', formData);
  };

  return (
    <div>
      <h1 className='flex flex-col'>User Form</h1>
      <form onSubmit={handleSubmit} className='flex flex-col '>
        <label htmlFor="name" className='p-4'>Name:</label>
        <input
        className='p-4 border-2 border-black'
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="content" className='p-4'>Content:</label>
        <input className='flex flex-col p-4 border-2 border-black'
          type="text"
          id="content"
          name="content"
          value={formData.content}
          onChange={handleChange}
          required
        />
          <label htmlFor="location" className='p-4'>urllocation:</label>
        <input
          className='p-4 border-2 border-black'
          type="text"
          id="location"
          name="location"
          value={formData.location}
          onChange={handleChange}
          required
        />
           <label htmlFor="Password" className='p-4'>Password:</label>
        <input
        className='p-4 border-2 border-black'
          type="text"
          id="Password"
          name="Password"
          value={formData.Password}
          onChange={handleChange}
          required
        />
        <button type="submit" className='my-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-32 my-10 mx-auto'>Submit</button>
      </form>
    </div>
  );
};
 
export default UserForm;
