import {React,useEffect,useState,useRef} from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
function SeeBlogsPage({index}){
    const [Text,setText]=useState(0);
    const location = useLocation();
     const { contentType } = location.state;
     const iframeRef = useRef(null);
     console.log(contentType)
    useEffect(()=>{
    async function currFunction(){
    const content=contentType.split('/')
    const contentNum=content[1]
    const contentWhere=content[0]
    const length=content[2]
    console.log(content[2])
    const response=await axios.get(`https://legal-counsel-5.onrender.com/${contentWhere}`);
    const data=response.data;
    let tot=parseInt(contentNum);
    console.log(tot)
    setText(data.data[tot].data)
}
     currFunction();
     const iframeDocument = iframeRef.current.contentDocument;

    // Create a <style> element and append CSS styles to it
    const styleElement = iframeDocument.createElement('style');
    styleElement.innerHTML = Text;
    iframeDocument.head.appendChild(styleElement);

    // Set the HTML content inside the <iframe>
    iframeDocument.body.innerHTML = Text;
    },[])
    useEffect(() => {
    const iframeDocument = iframeRef.current.contentDocument;

    // Create a <style> element and append CSS styles to it
    const styleElement = iframeDocument.createElement('style');
    styleElement.innerHTML = Text;
    iframeDocument.head.appendChild(styleElement);

    // Set the HTML content inside the <iframe>
    iframeDocument.body.innerHTML = Text;
  }, [Text]);
    return (
        <div>
        <iframe
      title="Styled HTML Renderer"
      ref={iframeRef}
      style={{
        "width":"100vw",
        "height":"100vh"
      }}
    />
        </div>
    )
}
export default SeeBlogsPage;